import React, {Component} from 'react';


class CloudBanner extends Component{
    state = {
        open: false
    };
    
    onOpenModal = () => {
        this.setState(prevState => ({
            open: !prevState.open
        }));
    };
    render(){
        return(
            <section className="software_banner_area d-flex align-items-center">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 d-flex align-items-center">
                            <div className="software_banner_content">
                                <h2 className="f_500 f_size_50 w_color wow fadeInLeft" data-wow-delay="0.2s">Welcome to<span className="f_700"> NIMA </span>Capital Group</h2>
                                <p className="w_color f_size_18 l_height30 mt_30 wow fadeInLeft" data-wow-delay="0.4s">At NIMA, we are passionate about creating value through strategic investments.</p>
                              
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="software_img wow fadeInRight" data-wow-delay="0.2s">
                                <img src={require("../../img/home5/banner_img.png")} alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default CloudBanner;